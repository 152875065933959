.popup-user {
  padding: 20px;
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    &:hover {
      background: #555;
    }
  }

  .popup-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;

    .user-avatar {
      min-width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .avatar-placeholder {
        width: 100%;
        height: 100%;
        background: #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #666;
        text-transform: uppercase;
      }
    }

    h2 {
      margin: 0;
      color: #333;
      font-size: 24px;
      word-break: break-word;
    }
  }

  .popup-content {
    .info-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 0 4px;

      .info-item {
        flex: 1 1 calc(50% - 16px);
        min-width: 250px;
        padding: 16px;
        background: #f8f9fa;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;

        &:hover {
          background: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.full-width {
          flex: 1 1 100%;
          min-width: 100%;
        }

        .label {
          display: block;
          color: #666;
          font-size: 14px;
          margin-bottom: 8px;
          font-weight: 500;
        }

        .value {
          display: block;
          color: #333;
          font-size: 16px;
          font-weight: 500;
          word-break: break-word;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .popup-user {
    padding: 16px;

    .popup-header {
      flex-direction: column;
      text-align: center;
      gap: 12px;

      .user-avatar {
        width: 64px;
        height: 64px;
      }

      h2 {
        font-size: 20px;
      }
    }

    .popup-content {
      .info-grid {
        .info-item {
          flex: 1 1 100%;
          min-width: 100%;
          padding: 12px;

          .label {
            font-size: 13px;
          }

          .value {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .popup-user {
    padding: 12px;

    .popup-header {
      margin-bottom: 16px;
      padding-bottom: 12px;

      .user-avatar {
        width: 56px;
        height: 56px;
      }

      h2 {
        font-size: 18px;
      }
    }

    .popup-content {
      .info-grid {
        gap: 12px;

        .info-item {
          padding: 10px;

          .label {
            font-size: 12px;
            margin-bottom: 4px;
          }

          .value {
            font-size: 14px;
          }
        }
      }
    }
  }
}
